import React from "react";
import Seo from "~/components/Seo";
import "~/features/marketing/general-page.css";

function TermsOfUsePage() {
  return (
    <div className="container general-page">
      <h2>RoundEd Learning Terms of Use</h2>
      <h3>Effective Date: March 24, 2020</h3>
      <p>
        These Terms of Use (“<strong>Terms</strong>”) apply to the products and
        services of RoundEd Learning Inc. (“<strong>RoundEd Learning</strong>”,
        “<strong>we</strong>”, “<strong>our</strong>” or “<strong>us</strong>”),
        a California corporation, including the website located at{" "}
        <a href="https://www.roundedlearning.com">www.roundedlearning.com</a>{" "}
        (the “<strong>Site</strong>”) and our web and mobile applications
        (collectively, including the Site, the “<strong>Products</strong>”).
      </p>
      <p>
        By opening a RoundEd Learning account or downloading or using a Product,
        you agree to comply with these Terms and our Privacy Policy. Also, you
        agree to ensure that any Child User (as defined below) associated with
        your Account (as defined below) comply with these Terms. When accepting
        these Terms on behalf of a Child User, the terms “you” and “your” shall
        be deemed to refer to both you and the Child User(s). If you are
        accepting these Terms on behalf of an entity or institution, you
        represent and warrant that you have the authority to enter into these
        Terms on behalf of such entity or institution. In such case, as used
        herein, “you” and “your” shall be deemed to refer to such entity or
        institution and all references to Child User shall refer to a child or
        children authorized by such entity or institution to access the
        Products. We may, at our sole discretion, modify any of these Terms at
        any time, and your continued use of the Products will be deemed
        acceptance of such modifications.
      </p>
      <p>
        Certain of the Products may also be governed by separate terms of
        service or sale documents that you agree to in order to purchase,
        download or access such Products (“<strong>Additional Terms</strong>
        ”).&nbsp;To the extent there is a conflict between these Terms and any
        Additional Terms, the Additional Terms will control unless the
        Additional Terms expressly state otherwise.
      </p>
      <h3>
        <strong>
          1)&nbsp;&nbsp;&nbsp;&nbsp; Account, Password, and Payments
        </strong>
      </h3>
      <p>
        a)&nbsp; <strong>Account</strong>. Users of the Products include “
        <strong>Child Users</strong>” (children under the age of 18 who use or
        access the Products) and “<strong>Adult Users</strong>” (parents, legal
        guardians, and teachers of Child Users) each of whom may have an account
        (each, an “<strong>Account</strong>”). Adult Users are responsible for
        setting up their Child User’s account and access to the Products.
      </p>
      <p>
        b)&nbsp;&nbsp;&nbsp;&nbsp; <strong>Passwords and Account Access</strong>
        . Every Account has one designated Adult User who must be at least 18
        years of age. You are responsible for maintaining the confidentiality of
        your password and Account information and the password and Account
        information of your Child Users. You agree that: (i) you will provide
        complete and accurate registration information about yourself and any
        individual you authorize to access your Account and keep your Account
        information up-to-date; (ii) you are solely responsible for all
        activities that occur under your Account; (iii) you will notify us
        immediately of any unauthorized Account use; (iv) we are in no way
        responsible for any loss that you may incur as a result of any
        unauthorized use of your Account and password; and (v) you will not
        sell, transfer, or assign your Account or any Account rights. If we
        learn that an ineligible User has created an Account, we may seek
        confirmation of the User’s status or deactivate the Account, without
        notice to the ineligible User.
      </p>
      <p>
        c)&nbsp;&nbsp;&nbsp;&nbsp; <strong>Account Fees</strong>. We may charge
        a fee, such as a course fee, for use of the Products. Unless otherwise
        specified, Products are paid for at the time of download and remain
        active for a period of one year. The game-based course can be played
        through by each registered user only once, although the instructional
        content and some of the repeatable activities in the course will be
        available for review or repeated play for the entire year. &nbsp;For
        existing users, we may change the course fee at any time in our sole
        discretion, if you wish to extend access to the content past the one
        year. All fees are payable in accordance with payment terms in effect at
        the time the fee becomes payable. We may offer promotional trial
        subscriptions to access the Products for free or at special discounted
        prices. If you sign up for a trial use, your rights to use the Products
        are limited by the terms of such trial and will terminate or renew
        according to the terms of your trial arrangement and/or any applicable
        Additional Terms. Failure to pay any fees may result in your inability
        to access or use the Products.
      </p>
      <p>
        d)&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <strong>Authorization to Charge for Products</strong>. You must either
        use a credit card or other payment mechanism accepted by us (each a “
        <strong>Payment Method</strong>”) to activate and maintain a paid
        Account. You authorize us to charge you through the Payment Method that
        you use when registering for an Account. You will also be responsible
        for charges (including applicable taxes) for any products or services
        that you order that are offered for sale through the Products. If we do
        not receive payment from the Payment Method you use, you agree to pay
        all amounts due hereunder upon our demand and will be solely responsible
        for any dispute with your payment provider. Should the Payment Method
        provided initially be declined for insufficient funds or any other
        reason, we reserve the right to attempt to recharge the Payment Method
        in full or in lesser installments of the initially incurred charge for
        the duration of the Account subscription you selected and for up to an
        additional 90 days. You will not be charged more than the amount for
        which you purchased your Product. You will be solely responsible for all
        overdraft fees and/or penalties that may be assessed by your payment
        provider. We use a third-party service provider to process payments on
        our behalf. You acknowledge and agree that in the event the third-party
        payment processor experiences a data breach that affects your
        information through no fault of RoundEd Learning, we will in no way be
        responsible or liable to you for any such breach.
      </p>
      <h3>
        <strong>
          2)&nbsp;&nbsp;&nbsp;&nbsp; Products Ownership and License
        </strong>
      </h3>
      <p>
        a)&nbsp;&nbsp;&nbsp;&nbsp; <strong>Ownership</strong>. As between us and
        you, the Products (including past, present, and future versions) are
        owned and controlled by us, and the Content included in the Products is
        protected by copyright, trademark, trade dress, patent, and other
        intellectual property rights and laws to the fullest extent possible. “
        <strong>Content</strong>”<strong> </strong>means all text, graphics,
        user interfaces, visual interfaces, photographs, logos, sounds, music,
        artwork, and computer code displayed on or available through the
        Products and the design, structure, selection, coordination, expression,
        and arrangement of such materials including, without limitation, (i)
        materials and other items relating to us and our products and services,
        including, without limitation, all activities, printables, characters,
        photographs, audio clips, sounds, pictures, videos, and animation, (ii)
        trademarks, logos, trade names, service marks, and trade identities of
        various parties, including ours (collectively, “
        <strong>Trademarks</strong>”), and (iii) other forms of intellectual
        property.
      </p>
      <p>
        b)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Limited License</strong>. Subject to your compliance with these
        Terms and any applicable Additional Terms, and your payment of any
        applicable fees, we grant you a limited, nonexclusive, revocable,
        nonassignable, and nontransferable license (the “
        <strong>License</strong>”) to access, display, view, and use the
        Products on a personal computer, mobile phone or other wireless device,
        or other internet-enabled device (each, an “
        <strong>Internet Device</strong>”) for your personal, noncommercial use
        only. The License does not give you any ownership of, or any other
        intellectual property interest in, the Products, and you cannot
        otherwise use the Products without our express prior written permission.
        All rights not expressly granted to you are reserved by us and/or our
        licensors and other third parties. Except as expressly provided in these
        Terms or any applicable Additional Terms, or with our express prior
        written consent, no part of the Products and no Content may be used,
        copied, reproduced, distributed, uploaded, posted, publicly displayed,
        translated, transmitted, broadcast, sold, licensed, or otherwise
        exploited for any purpose whatsoever. Any unauthorized use of the
        Products or any Content for any purpose is prohibited.
      </p>
      <h3>
        <strong>
          3)&nbsp;&nbsp;&nbsp;&nbsp; Product and Content Use Restrictions
        </strong>
      </h3>
      <p>
        You agree that you will not: (i) reverse engineer, disassemble, or
        modify any source or object code or any software or other products,
        services, or processes accessible through the Products, install any
        software, file, or code on the Products that is not authorized by us, or
        attempt to do so; (ii) engage in any activity that interferes with a
        User’s access to the Products or the proper operation of the Products;
        (iii) access or collect information from the Products using automated
        means (such as through scripts, robots, scrapers, or spiders); (iv) use
        any meta tags or other “hidden text” utilizing any of our Trademarks;
        (v) interfere with or circumvent any security feature of the Products or
        any feature that restricts or enforces limitations on the use of or
        access to the Products or Content; (vi) use the Products for commercial
        or political purposes; (vii) operate the Products as a service bureau;
        (viii) disclose, harvest, or otherwise collect information, including
        email addresses or other private information about any third party,
        including Child Users, without that party’s (or for Child Users, their
        parent’s, legal guardian’s or teacher’s, as applicable) express consent;
        or (ix) otherwise violate these Terms or any Additional Terms, or
        solicit, encourage, or facilitate anyone else to do so.
      </p>
      <h3>
        <strong>
          4)&nbsp;&nbsp;&nbsp;&nbsp; Linking to and from Our Products
        </strong>
      </h3>
      <p>
        a)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Linking to or Framing Our Products</strong>. When linking to our
        Products, you must adhere to the following requirements: (i) the link to
        the Products must not damage, disparage, present false information about
        or tarnish the goodwill associated with any of our Trademarks, products,
        services, and/or intellectual property; (ii) the link to the Products
        must not create the false appearance that your website and/or
        organization is sponsored by, endorsed by, affiliated with, or
        associated with us; (iii) no one may “frame” the Products or create a
        browser environment around any of the Content; and (iv) you may not link
        to the Products from a website: that is unlawful, abusive, indecent, or
        obscene; that promotes violence or illegal acts; that contains
        expressions of racism; that is libelous, defamatory, scandalous, or
        inflammatory; or that we otherwise deem inappropriate in our sole
        discretion. We reserve the right to prohibit linking to the Products for
        any reason, in our sole discretion, even if the linking complies with
        the requirements described above.
      </p>
      <p>
        b)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Links to Other Products and Third Parties</strong>. Any
        interactions, transactions, and other dealings that you have with any
        third parties found on or through the Products (including those that are
        linked to from the Products) are solely between you and the third party
        (including issues related to payments, delivery of goods, and
        warranties), and we disclaim all liability in connection therewith.
      </p>
      <h3>
        <strong>
          5)&nbsp;&nbsp;&nbsp;&nbsp; Governing Law/Dispute Resolution
        </strong>
      </h3>
      <p>
        a)&nbsp;&nbsp;&nbsp;&nbsp; <strong>Governing Law/Jurisdiction</strong>.
        These terms, and any Additional Terms, will be governed by and construed
        in accordance with the laws of the State of California, without regard
        to conflict of laws principles.
      </p>
      <p>
        b)&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <strong>Arbitration and Dispute Resolution</strong>. If any controversy,
        allegation, or claim arises out of or relates to the Products, these
        Terms, or any Additional Terms (each, a<br />“<strong>Dispute</strong>
        ”), you and we agree to the following resolution process with respect to
        the Dispute. To most efficiently resolve any Dispute, you and we agree
        to first discuss the Dispute informally for at least 30 days. To do so,
        the party who wants to raise the Dispute must first send to the other
        party a notice that must include: (1) a description of the Dispute; and
        (2) a proposed resolution (together, the “
        <strong>Dispute Notice</strong>”). If you want to raise a Dispute with
        RoundEd Learning, you must send your Dispute Notice at our contact
        information provided below. If we would like to subsequently discuss
        your Dispute Notice with you, we will contact you using the contact
        information included with your Dispute Notice. If we want to raise a
        Dispute, we will send our Dispute Notice to you at the email address
        that we have on file for you. If we do not have a valid email address on
        file for you, we will send our Dispute Notice to you through a means
        that complies with the service of process rules in the State of
        California.
      </p>
      <p>
        c)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>AAA. </strong>If we do not reach an agreed-upon resolution
        within 30 days of receipt of the Dispute Notice, you and we agree that
        the Dispute will be resolved solely by binding arbitration in accordance
        with the then-current Commercial Arbitration Rules of the American
        Arbitration Association (AAA). The arbitration will be heard and
        determined by a single neutral arbitrator who is a lawyer or retired
        judge, who will administer the proceedings in accordance with the AAA’s
        Supplementary Procedures for Consumer Related Disputes. In resolving the
        Dispute, the arbitrator will consider applicable law, the provisions of
        these Terms and any Additional Terms, and any facts based upon the
        record and no other basis and will issue a reasoned decision. You can
        obtain AAA procedures, rules, and fee information as follows:
      </p>
      <p>1-800-778-7879</p>
      <p>http://www.adr.org</p>
      <p>
        d)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>
          Nature, Limitations, and Location of Alternative Dispute Resolution
        </strong>
        . In arbitration, as with a court, the arbitrator will resolve the
        submitted Dispute and can issue a decision consistent with this Section
        5. However, WITH ARBITRATION, THERE IS NO JUDGE OR JURY; THE ARBITRATION
        PROCEEDINGS AND ARBITRATION ARE SUBJECT TO CERTAIN CONFIDENTIALITY
        RULES, AND JUDICIAL REVIEW OF THE ARBITRATION OUTCOME IS LIMITED. All
        parties to the arbitration will have the right, at their own expense, to
        be represented by an attorney or other advocate of their choosing. If an
        in-person arbitration hearing is required, then it will be conducted in
        the “metropolitan statistical area” (as defined by the U.S. Census
        Bureau) where you are a resident at the time the Dispute is submitted to
        arbitration. You and we will pay the administrative and arbitrator’s
        fees and other costs in accordance with the applicable arbitration
        rules, but if applicable arbitration rules or laws require us to pay a
        greater portion or all of such fees and costs in order for this Section
        5 to be enforceable, then we will have the right to elect to pay the
        fees and costs and proceed to arbitration. Discovery will be permitted
        pursuant to the applicable arbitration rules. The arbitrator’s decision
        must consist of a written statement stating the disposition of each
        claim of the Dispute and must provide a statement of the essential
        findings and conclusions on which the decision and any award (if any)
        are based. Judgment on the arbitration decision and award (if any) may
        be entered in any court that has jurisdiction over the parties.
      </p>
      <p>
        e)&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <strong>Small Claims Matters Are Excluded</strong>. Either of us may
        bring a qualifying claim of Disputes in small claims court.
      </p>
      <p>
        f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Injunctive Relief</strong>. The
        foregoing provisions of this Section 5 will not apply to any legal
        action taken by either party to seek an injunction or other equitable
        relief in conjunction with any intellectual property claim or claim
        related to unauthorized access to data through the Products (including,
        but not limited to, claims related to patent, copyright, trademark, and
        trade secrets, and claims relating to the access or retrieval of data
        through the Products using an automated process such as scraping).
      </p>
      <p>
        g)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Timing of Claim</strong>. To help resolve any issues between you
        and us promptly and directly, you and we agree that any Dispute Notice
        must be sent, or that any small claims or injunctive relief complaint
        permitted under this Section 5 must be filed, within one year after the
        events giving rise to the Dispute arose; otherwise, the Dispute is
        waived.
      </p>
      <p>
        h)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>No Class Actions</strong>. You and we agree that any Disputes
        will be arbitrated only on an individual basis and will not be
        consolidated with any other arbitrations or other proceedings that
        involve any claim or controversy of any other party.
      </p>
      <h3>
        <strong>
          6)&nbsp;&nbsp;&nbsp;&nbsp; Disclaimer of Representations and
          Warranties
        </strong>
      </h3>
      <p>
        YOUR ACCESS TO AND USE OF THE SERVICES IS AT YOUR SOLE RISK. THE
        SERVICES ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE,” AND “WITH ALL
        FAULTS” BASIS.
      </p>
      <p>
        Therefore, to the fullest extent permitted by law, we and our
        affiliates, and each of their respective employees, officers, directors,
        members, managers, shareholders, agents, vendors, licensors, licensees,
        contractors, customers, successors, and assigns (collectively, “
        <strong>Company Parties</strong>”), hereby to the maximum extent
        permitted by applicable law, disclaim and make no representations,
        warranties, endorsements, or promises, express or implied, as to the
        following:
      </p>
      <ul>
        <li>
          <p>
            the functions, features, or any other elements on, or made
            accessible through, the Products;
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            any products, services, or instructions offered or referenced at or
            linked through the Products;
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            whether the Products (and their Content), or the servers that make
            the Products available, are free from any harmful components
            (including viruses, Trojan horses, and other technologies that could
            adversely impact your Internet Device);
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            the specific availability of the Products, and whether any defects
            in the Products will be repaired or will be repaired in a particular
            time frame; and
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            whether your use of the Products is lawful in any particular
            jurisdiction.
          </p>
        </li>
      </ul>
      <p>
        EXCEPTING ONLY AS MAY BE SPECIFICALLY SET FORTH IN ANY ADDITIONAL TERMS,
        THE COMPANY PARTIES HEREBY FURTHER DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, NONINFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL
        PROPERTY RIGHTS OF THIRD PARTIES, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT,
        SYSTEM INTEGRATION, AND FREEDOM FROM ERRORS, COMPUTER VIRUSES, OR OTHER
        HARMFUL ELEMENTS.
      </p>
      <p>
        Some jurisdictions limit or do not allow the disclaimer of implied or
        other warranties, so the above disclaimers may not apply to the extent
        that such jurisdictions’ laws are applicable.
      </p>
      <h3>
        <strong>7)&nbsp;&nbsp;&nbsp;&nbsp; Limitations of Liability</strong>
      </h3>
      <p>
        UNDER NO CIRCUMSTANCES WILL ANY COMPANY PARTIES BE RESPONSIBLE OR LIABLE
        FOR ANY LOSS OR DAMAGES OF ANY KIND, INCLUDING PERSONAL INJURY OR DEATH
        OR FOR ANY DIRECT, INDIRECT, ECONOMIC, EXEMPLARY, SPECIAL, PUNITIVE,
        INCIDENTAL, OR CONSEQUENTIAL LOSSES OR DAMAGES THAT ARE DIRECTLY OR
        INDIRECTLY RELATED TO:
      </p>
      <ul>
        <li>
          <p>the Products or the Content;</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            your use of or inability to use the Products or the performance of
            the Products;
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            the failure of a Child User to learn or otherwise benefit
            educationally from his or her use of the Products;
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            any action taken in connection with an investigation by Company
            Parties or law enforcement authorities regarding your access to or
            use of the Products;
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            any action taken in connection with copyright or other intellectual
            property owners or other rights owners;
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            any errors or omissions in the Products’ technical operation; or
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            any damage to any user’s computer, hardware, software, modem, or
            other equipment or technology, including damage from any security
            breach or from any virus, bugs, tampering, fraud, error, omission,
            interruption, defect, delay in operation or transmission, computer
            line, or network failure or any other technical or other
            malfunction, including losses or damages in the form of lost
            profits, loss of goodwill, loss of data, work stoppage, inaccuracy
            of results, or equipment failure or malfunction.
          </p>
        </li>
      </ul>
      <p>
        The foregoing limitations of liability will apply even if any of the
        events or circumstances were foreseeable and even if Company Parties
        were advised of or should have known of the possibility of such losses
        or damages, regardless of whether you bring an action of contract,
        negligence, strict liability, or tort (including whether caused, in
        whole or in part, by negligence, force majeure, telecommunications
        failure, or destruction of the Products).
      </p>
      <p>
        Some jurisdictions do not allow the exclusion or limitation of
        incidental or consequential damages of the sort that are described
        above, so the above limitation or exclusion may not apply to you.
      </p>
      <p>
        EXCEPT AS MAY BE PROVIDED IN ANY ADDITIONAL TERMS, TO THE FULLEST EXTENT
        PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY PARTIES’ TOTAL
        LIABILITY TO YOU IN CONNECTION WITH YOUR ACCESS TO AND USE OF THE
        PRODUCTS AND YOUR RIGHTS UNDER THESE TERMS EXCEED THE AMOUNT PAID BY YOU
        TO US DURING THE PREVIOUS 12 MONTHS FOR ALL POSSIBLE DAMAGES, LOSSES,
        AND CAUSES OF ACTION.
      </p>
      <h3>
        <strong>8)&nbsp;&nbsp;&nbsp;&nbsp; Indemnity</strong>
      </h3>
      <p>
        To the maximum extent allowed by law, you agree to indemnify, defend,
        and hold harmless the Company Parties from and against all losses,
        expenses, damages, and costs, including reasonable attorneys’ fees,
        resulting from: (a) your breach or alleged breach of these Terms; (b)
        your use of the Products or activities in connection with the Products;
        (c) your violation of any law, rule, or regulation; or (d) your
        violation of any third-party rights. The Company Parties reserve the
        right to assume, at their sole expense, the exclusive defense and
        control of any matter subject to indemnification by you, in which event
        you will fully cooperate with the Company Parties in asserting any
        available defenses. You will not, in any event, settle any claim without
        our prior written consent. If any Child User you authorize to use or
        access the Products disaffirms any or all of these Terms, you agree to
        defend, indemnify, and hold Company Parties harmless for any damages
        that Company Parties suffer by the Child User’s disaffirmance.
      </p>
      <h3>
        <strong>
          9)&nbsp;&nbsp;&nbsp;&nbsp; Infringement Policy and Reporting
          Procedure.
        </strong>
      </h3>
      <p>
        In accordance with the Digital Millennium Copyright Act of 1998 (the “
        <strong>DMCA</strong>”), our designated agent to receive notices of
        copyright infringement may be reached by email at{" "}
        <a href="mailto:privacy@aofl.com">info@roundedlearning.com </a>or by
        postal mail 218 Del Valle Court, Pleasanton CA 93466.{" "}
      </p>
      <p>
        If you believe that your material has been posted on, or distributed
        via, the Products in a way that constitutes copyright infringement,
        please provide the following information to our designated agent as
        required by the DMCA: (i) a physical or electronic signature of a person
        authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed (the “complaining party”); (ii) identification of
        the copyrighted work(s) claimed to have been infringed; (iii)
        identification of the material that is claimed to be infringing or to be
        the subject of infringing activity and information reasonably sufficient
        to permit us to locate the material; (iv) information reasonably
        sufficient to permit us to contact the complaining party; (v) a
        statement that the complaining party (name, address, telephone number,
        and email address) has a good-faith belief that use of the material in
        the manner complained of is not authorized by the copyright owner, its
        agent, or the law; and (vi) a statement that the information in the
        notification is accurate, and under penalty of perjury, that the
        complaining party is authorized to act on behalf of the owner of an
        exclusive right that is allegedly infringed. The DMCA provides that a
        person who knowingly materially misrepresents that material or an
        activity is infringing may be subject to liability. We may send the
        information in the notice from the complaining party to the person who
        provided the allegedly infringing material.
      </p>
      <h3>
        <strong>10)&nbsp; Wireless Access</strong>
      </h3>
      <p>
        The Products are available to you via your wireless Internet Device.
        Your carrier may charge standard messaging, data, and other fees, which
        may appear on your wireless bill or be deducted from your prepaid
        balance. Your carrier may prohibit or restrict certain Wireless
        Features, and certain Wireless Features may be incompatible with your
        carrier or wireless Internet Device. You agree that as to the Wireless
        Features for which you are registered, we may send communications to
        your wireless Internet Device regarding us or other parties. If you have
        registered via the Products for Wireless Features, then you agree to
        notify us of any changes to your wireless number (including phone
        number) and update your Account to reflect the changes.
      </p>
      <h3>
        <strong>11)&nbsp; Submission of Feedback</strong>
      </h3>
      <p>
        Under certain circumstances, we might ask Adult Users for feedback or
        ideas as to their experiences with our Products. If you choose to
        provide us with feedback, you acknowledge and agree (i) that you have no
        expectation of review, compensation, or consideration of any type for
        any such feedback or ideas; and (ii) we will be free to use and exploit
        such feedback or ideas in our discretion and without compensation or
        obligation to you.
      </p>
      <h3>
        <strong>12)&nbsp; General Provisions</strong>
      </h3>
      <p>
        a)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Termination and Suspension</strong>. We reserve the right to
        discontinue the Products or suspend or terminate your access to them,
        including any Accounts, at any time, without notice, for any reason and
        without any obligation to you or any third party. If any information
        that you provide, or if we have reasonable grounds to suspect that any
        information that you provide, is false, inaccurate, or otherwise
        violates these Terms or any Additional Terms, then we may suspend or
        terminate your Account or deny you access to all or part of the
        Products. Any suspension or termination will not affect your obligations
        to us, including any payment obligations to us, and you will not be
        entitled to a refund of any payments. Upon suspension or termination of
        your access to the Products, or upon notice from us, your License to use
        the Products will terminate immediately.
      </p>
      <p>
        b)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Communications</strong>. When you communicate with us
        electronically, such as via a Product communication tool, you consent to
        receive communications from us electronically. You agree that all
        agreements, notices, disclosures, and other communications that we
        provide to you electronically, satisfy any legal requirement that such
        communications be in writing.
      </p>
      <p>
        c)&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <strong>Operation of Products; International Issues</strong>. The
        Products are controlled and operated by us from the State of California,
        United States, and we provide the Products for use only by persons
        located in the United States. Access to the Products may not be legal by
        certain persons or in certain countries. If you access the Products from
        outside the United States, you do so on your own initiative and are
        responsible for compliance with local laws.
      </p>
      <p>
        d)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Severability; Interpretation</strong>. If any provision of these
        Terms, or any applicable Additional Terms, is for any reason deemed
        unenforceable by a court or arbitrator, you agree that every attempt
        will be made to give effect to the parties’ intentions as reflected in
        that provision, and the remaining provisions contained in such terms
        will continue in full force and effect. You agree that these Terms, and
        Additional Terms, will not be construed against us because we drafted
        them.
      </p>
      <p>
        e)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Assignment</strong>. We may assign our rights and obligations
        under these Terms, or any Additional Terms, in whole or in part, to any
        party at any time without any notice. These Terms, and any Additional
        Terms, may not be assigned by you, and you may not delegate your duties
        under them.
      </p>
      <p>
        f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>No Waiver</strong>. No waiver
        by us of any of these Terms or any Additional Terms will be of any force
        or effect unless made in writing and signed by a duly authorized officer
        of RoundEd Learning.&nbsp;
      </p>
      <p>
        g)&nbsp;&nbsp;&nbsp;&nbsp; <strong>Update to Terms</strong>. We reserve
        the right to modify these Terms, or any Additional Terms, from time to
        time in our sole discretion (the “<strong>Updated Terms</strong>”). You
        agree that any Updated Terms will be effective immediately upon our
        posting them on the Products and, if you have an Account, either by
        displaying an alert next to the link to the Terms, displaying an alert
        upon log in to the Products, or by directly communicating them to you
        (e.g., via the email address associated with your Account), provided
        that (i) any modification to Section 5 related to dispute resolution
        shall not apply to any Dispute initiated prior to the applicable
        modification, and (ii) any modification to provisions related to fees
        and billing shall not apply to fees incurred prior to the applicable
        modification. If you do not cancel your Account within seven days after
        receiving notice of Updated Terms as described above, or if you continue
        to use the Products after receiving notice of Updated Terms, you agree
        to comply with, and to be bound by, the Updated Terms.
      </p>
      <p>
        h)&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>Contact Us</strong>. RoundEd Learning Inc. is located in the
        United States and is the operator of the Products. If you have questions
        regarding the Terms and issues related to the Products or your Account,
        you can contact us via mail at the address set forth below:
      </p>
      <p>RoundEd Learning Inc.</p>
      <p>Pleasanton, CA 94566</p>
      <p>Attn: Vidya Raman, CEO</p>
      <p>
        <a href="mailto:info@roundedlearning.com">info@roundedlearning.com</a>
        &nbsp;
      </p>
    </div>
  );
}

export function Head() {
  return <Seo title="Terms of Use" />;
}

export default TermsOfUsePage;
